import * as React from "react"
import { Link } from "gatsby"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {Button, NavDropdown} from "react-bootstrap"
import "../style/index.scss"
import Container from "react-bootstrap/Container";
import SegmentIcon from '@mui/icons-material/Segment';
import AccountTree from "@mui/icons-material/AccountTree";
import Logout from "@mui/icons-material/Logout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PeopleIcon from "@mui/icons-material/People";
import CodeIcon from '@mui/icons-material/Code';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

const HeaderHead = ({classes = [], children, fluid = false, fixed = false}) => {
    return (
            <Navbar expand="lg" fixed={fixed ? "top" : false} className={["mb-3", "bg-white", fixed ? "border-bottom" : "", ...classes].join(' ')}>
                <Container fluid={fluid}>
                <Navbar.Brand as={Link} to="/">
                    Firstparty
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto d-flex justify-content-around">
                        <NavDropdown renderMenuOnMount className="my-0 my-md-3 fp-navigation-dropdown" title="Solutions" id="fp-nav-dropdown">
                            <Row className="solutions-content gx-0">
                                <Col xs={12} md={6} className="mb-5 mb-md-0 order-2 order-sm-1">
                                    <div className="workflows-highlight bg-dark h-100 p-5 d-flex justify-content-center flex-column" >
                                        <h5 className="text-light">Connect Everything with Workflows</h5>
                                        <p className="text-gray-400">Workflows guide you through configuring multiple Firstparty components to help achieve an objective.</p>
                                        <Button className="btn-light-soft" size="sm" href="/workflows/">Explore Workflows</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} className="order-1 order-sm-2">
                                    <NavDropdown.Item href="/solutions/attribution/" className="py-3 py-md-4 mx-5 mx-lg-0 my-0">
                                        <div className="d-flex">
                                            <div className="icon icon-sm text-primary my-auto">
                                                <Logout />
                                            </div>
                                            <div className="ms-5">
                                                <h6 className="fw-bold text-uppercase text-primary mb-0">
                                                    Attribution
                                                </h6>
                                                <p className="fs-sm text-gray-700 mb-0">
                                                    Advanced Conversion Tracking
                                                </p>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/solutions/audiences/" className="py-3 py-md-4 mx-5 mx-lg-0 my-0">
                                        <div className="d-flex">
                                            <div className="icon icon-sm text-primary my-auto">
                                                <PeopleIcon />
                                            </div>
                                            <div className="ms-5">
                                                <h6 className="fw-bold text-uppercase text-primary mb-0">
                                                    Audiences
                                                </h6>
                                                <p className="fs-sm text-gray-700 mb-0">
                                                    Decentralized Audience Syncing
                                                </p>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/solutions/analytics/" className="py-3 pt-0 py-md-4 mx-5 mx-lg-0 my-0">
                                        <div className="d-flex">
                                            <div className="icon icon-sm text-primary my-auto">
                                                <SegmentIcon />
                                            </div>
                                            <div className="ms-5">
                                                <h6 className="fw-bold text-uppercase text-primary mb-0">
                                                    Analytics
                                                </h6>
                                                <p className="fs-sm text-gray-700 mb-0">
                                                    Accurate & Portable Measurement
                                                </p>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/solutions/automation/" className="py-3 py-md-4 mx-5 mx-lg-0 my-0">
                                        <div className="d-flex">
                                            <div className="icon icon-sm text-primary my-auto">
                                                <AccountTree />
                                            </div>
                                            <div className="ms-5">
                                                <h6 className="fw-bold text-uppercase text-primary mb-0">
                                                    Automation
                                                </h6>
                                                <p className="fs-sm text-gray-700 mb-0">
                                                    Event-driven Rules Engine
                                                </p>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                </Col>
                            </Row>
                        </NavDropdown>
                        <Nav.Link className="my-0 mx-5 mx-lg-0 my-lg-3" as={Link} to="/pricing/">Pricing</Nav.Link>
                        <NavDropdown renderMenuOnMount className="my-0 my-md-3 fp-navigation-dropdown" title="Documentation" id="fp-nav-dropdown">
                            <Row className="gx-0">
                                <Col xs={12} className="order-1 order-sm-2">
                                    <NavDropdown.Item href="/docs/introduction/" className="py-2 pt-0 py-md-4 mx-5 px-md-5 mx-lg-0 my-0">
                                        <div className="d-flex">
                                            <div className="icon icon-sm text-primary my-auto">
                                                <IntegrationInstructionsIcon />
                                            </div>
                                            <div className="ms-5">
                                                <h6 className="fw-bold text-uppercase text-primary mb-0">
                                                    Firstparty Documentation
                                                </h6>
                                                <p className="fs-sm text-gray-700 mb-0">
                                                    Core Concepts & Instruction
                                                </p>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/docs/api/reference/" className="py-3 py-md-4 mx-5 px-md-5 mx-lg-0 my-0">
                                        <div className="d-flex">
                                            <div className="icon icon-sm text-primary my-auto">
                                                <CodeIcon />
                                            </div>
                                            <div className="ms-5">
                                                <h6 className="fw-bold text-uppercase text-primary mb-0">
                                                    API Documentation
                                                </h6>
                                                <p className="fs-sm text-gray-700 mb-0">
                                                    HTTP API Reference
                                                </p>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                </Col>
                            </Row>
                        </NavDropdown>
                        <Nav.Link className="my-0 mx-5 mx-lg-0 my-lg-3" as={Link} to="/news/">News</Nav.Link>
                    </Nav>
                    <div className="d-block d-lg-none">
                        <hr />
                    </div>
                    <div className="ms-auto d-grid gap-2 d-md-block mt-5 mt-lg-0 mb-5 mb-lg-0">
                        <Button className="mx-3" size="sm" variant="link" href="https://app.firstpartyhq.com/authentication/login">Sign in</Button>
                        <Button className="mx-3" size="sm" variant="primary" href="https://app.firstpartyhq.com/authentication/signup">Get Started</Button>
                    </div>
                </Navbar.Collapse>
                </Container>
            </Navbar>
    )
}

export default HeaderHead;